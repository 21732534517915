import { PAGE_ABOUT_US, PAGE_WHAT_YOU_GET, PAGE_CONTACT_US, PAGE_FAQ, PAGE_HOW_IT_WORKS, PAGE_INSPIRATION, PAGE_PRESS, PAGE_CAREERS, PAGE_EMPLOYERS, PAGE_THINGS_WE_DO, PAGE_PRICING, PAGE_PRIME_PRO, PAGE_GIFT } from '@store/constants/routes';
var OurCompanySection = {
  title: 'footer.our-company',
  links: [{
    title: 'footer.about-us',
    href: PAGE_ABOUT_US
  }, {
    title: 'footer.our-inspiration',
    href: PAGE_INSPIRATION
  }, {
    title: 'footer.press',
    href: PAGE_PRESS
  }, {
    title: 'footer.careers',
    href: PAGE_CAREERS
  }, {
    title: 'footer.contact',
    href: PAGE_CONTACT_US
  }]
};

var subscriptionSection = function subscriptionSection(showPrimePro) {
  var links = [{
    title: 'footer.how-it-works',
    href: PAGE_HOW_IT_WORKS
  }, {
    title: 'footer.what-you-get',
    href: PAGE_WHAT_YOU_GET
  }, {
    title: 'footer.things-we-do',
    href: PAGE_THINGS_WE_DO
  }, {
    title: 'footer.gifting',
    href: PAGE_GIFT
  }, {
    title: 'footer.pricing',
    href: PAGE_PRICING
  }];

  if (showPrimePro) {
    links.push({
      title: 'footer.prime-pro',
      href: PAGE_PRIME_PRO
    });
  }

  links.push({
    title: 'footer.faqs',
    href: PAGE_FAQ
  });
  return {
    title: 'footer.subscription',
    links: links
  };
};

var ForEmployers = {
  title: 'footer.for-employers-title',
  links: [{
    title: 'footer.corporate-wellness',
    href: PAGE_EMPLOYERS
  }]
};
export var FOOTER_LINK_SECTIONS = function FOOTER_LINK_SECTIONS(lcLocale) {
  var showPrimePro = lcLocale === 'ja-jp';
  return [OurCompanySection, subscriptionSection(showPrimePro), ForEmployers];
};